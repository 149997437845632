import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import HeroCTA from '../../components/HeroCTA';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import CampaignCard from '../../components/cards/CampaignCard';
import { campaignsQuery } from '../../groq/pages/campaigns';
import { sanityClient } from '../../lib/sanity.server';
import {
  CampaignEntryType,
  CampaignSingularType,
  DefaultSEOType,
  FormOverrideType,
  ImageWithAltType,
  MenuType,
  NewCarEntriesType,
  RelatedDealerType,
  SEOType,
  SettingsType,
} from '../../types';
import fetchSanityData from '../../utils/fetchSanityData';

interface PageProps {
  data: {
    menus: MenuType;
    settings: SettingsType;
    content: BaseContentType;
    campaigns: CampaignSingularType[];
  };
}

type BaseContentType = {
  title: string;
  overline?: string;
  description: string;
  image: ImageWithAltType;
  formOverride?: FormOverrideType;
  newCarSectionTitle: string;
  servicesSectionTitle: string;
  accessoriesSectionTitle: string;
  otherCampaignsSectionTitle: string;
  newCarEntries: NewCarEntriesType;
  serviceEntry: CampaignEntryType;
  accessoryEntry: CampaignEntryType;
  seo?: SEOType;
  defaultSEO: DefaultSEOType;
};

interface CampaignGroupProps {
  heading: string;
  baseData: CampaignEntryType;
  items: (CampaignSingularType & { slug: string })[];
  basePath: string;
}

type EntrancesType = {
  brands: Set<string>;
  usedCars: (CampaignSingularType & { slug: string })[];
  services: (CampaignSingularType & { slug: string })[];
  accessories: (CampaignSingularType & { slug: string })[];
  topics: (CampaignSingularType & { slug: string })[];
};

function CampaignPage({ data }: PageProps) {
  const { content, menus, settings } = data;

  const campaignData = content;

  const [campaigns, setCampaigns] = useState<CampaignSingularType[]>([]);
  const router = useRouter();
  const [dealers, setDealers] = useState<RelatedDealerType[] | undefined>(undefined);

  // INFO Fetch dealers
  useEffect(() => {
    const fetchDealers: (groq: string, params: { [key: string]: string }) => void = async (
      groq,
      params,
    ) => {
      const sanityData = await fetchSanityData(groq, params);
      setDealers(sanityData);
    };

    if (router.query.dealerId) {
      fetchDealers('dealerById', { dealerId: router.query.dealerId as string });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  // INFO Fetch campaigns
  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const sanityData = await fetchSanityData('campaignsPage');
      setCampaigns(sanityData.campaigns);
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entrances: EntrancesType = {
    brands: new Set(),
    usedCars: [],
    services: [],
    accessories: [],
    topics: [],
  };

  campaigns.forEach((item) => {
    if (item.showOnEditorialPage?._ref === 'usedCarPage' && !item.campaignItem) {
      entrances.usedCars.push({ ...item, slug: '/kjope-bil/bruktbil' || '/' });
      return;
    }
    const campaignType = item.campaignItem?._type;
    if (item.brandSlug) {
      entrances.brands.add(item.brandSlug);
      return;
    }
    if (campaignType === 'service') {
      entrances.services.push({ ...item, slug: item.campaignItem.href });
      return;
    }
    if (campaignType === 'accessory') {
      entrances.accessories.push({
        ...item,
        slug: `/tilbehor/${item.campaignItem.accessoryTag}${item.campaignItem.href}`,
      });
      return;
    }
    if (campaignType === 'accessoryTag') {
      entrances.accessories.push({
        ...item,
        slug: `/tilbehor/${item.campaignItem.href}`,
      });
      return;
    }
    if (campaignType === 'topic') {
      entrances.topics.push({ ...item, slug: item.campaignItem.href });
    }
  });

  const getEntryByBrand = (brand: string) => {
    return (
      <CampaignCard
        title={campaignData.newCarEntries[brand].title}
        overline={campaignData.newCarEntries[brand].overline}
        description={campaignData.newCarEntries[brand].description}
        image={campaignData.newCarEntries[brand].image}
        key={campaignData.newCarEntries[brand].title}
        href={
          campaignData.newCarEntries[brand].externalLandingPage ||
          `/kjope-bil/nybil/${encodeURI(brand)}`
        }
      />
    );
  };

  // Custom ordering of campaignBrands
  const ordering: { [key: string]: number } = {};
  const sortOrder = ['volkswagen', 'volkswagen-nyttekjoretoy', 'audi', 'skoda'];
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
  return (
    <Layout settings={settings} seo={content.seo} defaultSEO={content.defaultSEO} menus={menus}>
      <HeroCTA
        title={content.title}
        overline={content.overline}
        description={content.description}
        formOverride={content.formOverride}
      />

      <Section>
        <div className="flex flex-col gap-20">
          {!!Array.from(entrances.brands).length && (
            <div>
              <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
                {content.newCarSectionTitle}
              </h2>
              <div className="grid gap-5">
                {Array.from(entrances.brands)
                  .sort((a, b) => ordering[a] - ordering[b])
                  .map((brand) => getEntryByBrand(brand.toLowerCase()))}
              </div>
            </div>
          )}
          {entrances.usedCars.length > 0 && (
            <div>
              <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Bruktbil</h2>
              <div className="grid gap-5">
                {entrances.usedCars.map((item) => {
                  return (
                    <CampaignCard
                      title={item.title}
                      overline={item.overline}
                      description={item.pitch}
                      image={item.image}
                      key={item.title}
                      href={encodeURI(item.slug)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {entrances.services.length > 0 && (
            <CampaignGroup
              heading={content.servicesSectionTitle}
              /*
               *This path is hardcoded because the editors have agreed to
               * use this for all services.
               * If they change this slug in the future,
               * it needs to be updated herea s well.
               * */
              basePath="vedlikehold"
              baseData={campaignData.serviceEntry}
              items={entrances.services}
            />
          )}
          {entrances.accessories.length > 0 && (
            <CampaignGroup
              heading={content.accessoriesSectionTitle || 'Tilbehør'}
              basePath="tilbehor"
              baseData={campaignData.accessoryEntry}
              items={entrances.accessories}
            />
          )}
          {entrances.topics?.length > 0 && (
            <div>
              <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
                {content.otherCampaignsSectionTitle}
              </h2>
              <div className="grid gap-5">
                {entrances.topics.map((item) => (
                  <CampaignCard
                    title={item.title}
                    overline={item.overline}
                    description={item.pitch}
                    image={item.image}
                    key={item.title}
                    href={encodeURI(item.slug)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Section>
    </Layout>
  );
}

function CampaignGroup({ heading, baseData, items, basePath }: CampaignGroupProps) {
  return (
    <div>
      <h2 className="mb-5 text-3xl font-semibold lg:mb-1 lg:text-4xl">{heading}</h2>
      <div className="grid gap-5">
        {items.length > 3 ? (
          <CampaignCard
            title={baseData.title}
            description={baseData.description}
            image={baseData.image}
            overline={baseData.overline}
            key={baseData.title}
            href={encodeURI(basePath)}
          />
        ) : (
          <>
            {items.map((item) => {
              return (
                <CampaignCard
                  title={item.title}
                  description={item.pitch}
                  overline={item.overline}
                  image={item.image}
                  key={item.title}
                  href={encodeURI(item.slug)}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export async function getStaticProps() {
  const today = new Date().toISOString();
  const data = await sanityClient.fetch(campaignsQuery, { today });
  if (!data) return { notFound: true };

  return {
    props: { data },
    revalidate: 1,
  };
}

export default CampaignPage;
